import HymnForm from "components/Admin/HymnForm";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import http from "services/axios";
import { ToastError, ToastSuccess } from "utils/swalToast";

const HymnEdit = () => {
  const history = useHistory();
  let { hymnId } = useParams();
  const [hymn, setHymn] = useState({});

  const onSubmit = (data) => updateHymn(data);

  const updateHymn = async (data) => {
    try {
      const response = await http.put(`/v1/hymns/${hymnId}`, data);
      const hymn = response.data;
      if (hymn.id) {
        ToastSuccess("Himno actualizado");
        history.push("/admin/hymns");
      }
    } catch (error) {
      ToastError("Error al actualizar el himno: " + error);
    }
  };

  const getHymn = async () => {
    try {
      const response = await http.get(`/v1/hymns/${hymnId}`);
      setHymn(response.data);
    } catch (error) {
      ToastError("Error al cargar el himno: " + error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getHymn();
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Actualizar himno
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          {Object.keys(hymn).length > 0 && (
            <HymnForm onSubmit={onSubmit} hymn={hymn} />
          )}
        </div>
      </div>
    </>
  );
};

export default HymnEdit;
