import HymnTable from "components/Admin/HymnTable";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import http from "services/axios";
import { ToastError } from "utils/swalToast";
const Hymns = () => {
  const [hymns, setHymns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await getHymns();
    }

    fetchData();
  }, []);

  const getHymns = async () => {
    setIsLoading(true);
    try {
      const response = await http.get("/v1/hymns");
      setHymns(response.data);
      setIsLoading(false);
    } catch (error) {
      ToastError("Error al cargar los himnos");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full mb-6  shadow-lg rounded bg-white"
            }
          >
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3 className={"font-semibold text-lg text-blueGray-700"}>
                    Himnos
                  </h3>
                </div>
                <Link
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  to="/admin/hymns/new"
                >
                  Nuevo
                </Link>
              </div>
            </div>

            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              {isLoading ? (
                <div className="flex justify-center items-center h-32">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-light-blue-500"></div>
                </div>
              ) : (
                <HymnTable hymns={hymns} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hymns;
