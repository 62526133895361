import CategoryForm from "components/Admin/CategoryForm";
import React from "react";
import { useHistory } from "react-router-dom";
import http from "services/axios"
import { ToastError, ToastSuccess } from "utils/swalToast";

const CategoryNew = () => {
  const history = useHistory();
  const onSubmit = (data) => createNewCategory(data);

  const createNewCategory = async (data) => {
    try {
      const response = await http.post("/v1/categories", data);
      const category = response.data;
      if (category.id) {
        ToastSuccess("Categoría creada");
        history.push("/admin/categories");
      }
    }
    catch (error) {
      ToastError("Error al crear la categoría: " + error);
    }
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Nueva categoría</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <CategoryForm onSubmit={onSubmit} />
        </div>
      </div>
    </>
  );
};

export default CategoryNew;
