import CategoryForm from "components/Admin/CategoryForm";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import http from "services/axios";
import { ToastError, ToastSuccess } from "utils/swalToast";

const CategoryEdit = () => {
  const history = useHistory();
  let { categoryId } = useParams();
  const [category, setCategory] = useState({});

  useEffect(() => {
    getCategory();
  }, []);

  const onSubmit = (data) => updateCategory(data);

  const updateCategory = async (data) => {
    try {
      const response = await http.put(`/v1/categories/${categoryId}`, data);
      const category = response.data;
      if (category.id) {
        ToastSuccess("Categoría actualizada");
        history.push("/admin/categories");
      }
    } catch (error) {
      ToastError("Error al actualizar la categoría: " + error);
    }
  };

  const getCategory = async () => {
    try {
      const response = await http.get(`/v1/categories/${categoryId}`);
      setCategory(response.data);
    } catch (error) {
      ToastError("Error al cargar la categoría: " + error);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Actualizar categoría
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          {Object.keys(category).length > 0 && (
            <CategoryForm onSubmit={onSubmit} category={category} />
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryEdit;
