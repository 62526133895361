import CardCategories from "components/Cards/CardCategories";
import React from "react";

const Categories = () => {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardCategories color="light" />
        </div>
      </div>
    </>
  );
};

export default Categories;
