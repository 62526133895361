import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import PrivateRoute from "routes/PrivateRoute";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Protegemos la ruta de admin */}
        <PrivateRoute path="/admin" component={Admin} />

        {/* add routes with layouts */}
        <Route path="/auth" component={Auth} />

        {/* add routes without layouts */}
        <Route path="/landing" exact component={Landing} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/" exact component={Auth} />
        {/* add redirect for first page */}
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
