import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderEmpty from "components/Headers/HeaderEmpty";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import Categories from "views/admin/Categories";
import CategoryNew from "views/admin/CategoryNew";
import CategoryEdit from "views/admin/CategoryEdit";
import Hymns from "views/admin/Hymns";
import HymnNew from "views/admin/HymnNew";
import HymnEdit from "views/admin/HymnEdit";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderEmpty />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/maps" exact component={Maps} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route path="/admin/categories" exact component={Categories} />
            <Route path="/admin/categories/new" exact component={CategoryNew} />
            <Route path="/admin/categories/:categoryId/edit" exact component={CategoryEdit} />
            <Route path="/admin/hymns" exact component={Hymns} />
            <Route path="/admin/hymns/new" exact component={HymnNew} />
            <Route path="/admin/hymns/:hymnId/edit" exact component={HymnEdit} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
