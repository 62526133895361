import HymnForm from 'components/Admin/HymnForm'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import http from 'services/axios'
import { ToastError, ToastSuccess } from 'utils/swalToast'

const HymnNew = () => {
  const history = useHistory();
  const onSubmit = (data) => createNewHymn(data);

  const createNewHymn = async (data) => {
    try {
      const response = await http.post("/v1/hymns", data);
      const hymn = response.data;
      if (hymn.id) {
        ToastSuccess("Himno creado");
        history.push("/admin/hymns");
      }
    }
    catch (error) {
      ToastError("Error al crear el himno: " + error);
    }
  }

  return (
    <>
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="rounded-t bg-white mb-0 px-6 py-6">
        <div className="text-center flex justify-between">
          <h6 className="text-blueGray-700 text-xl font-bold">Nuevo Himno</h6>
        </div>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <HymnForm onSubmit={onSubmit}/>
      </div>
    </div>
  </>
  )
}

export default HymnNew