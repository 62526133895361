import React from "react";
import { createPopper } from "@popperjs/core";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastSuccess, ToastError } from "utils/swalToast";
import http from "services/axios";

const CategoryDropdown = ({ category }) => {
  const history = useHistory();

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const deleteCategory = async () => {
    try {
      const response = await http.delete(`/v1/categories/${category.id}`);
      if (response.status === 204) {
        ToastSuccess("Categoría eliminada");
        // history.go(0) : es una forma de recargar la página
        history.go(0);
      }
    } catch (error) {
      ToastError("Error al eliminar la categoría: " + error);
    }
  };

  return (
    <>
      <a
        className="text-blueGray-500 py-1 px-3"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <Link
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          to={`/admin/categories/${category.id}/edit`}
        >
          Editar
        </Link>
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={deleteCategory}
        >
          Eliminar
        </a>
      </div>
    </>
  );
};

export default CategoryDropdown;
