import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('authToken');  // Verifica si el token está presente

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />  // Redirige al login si no hay token
        )
      }
    />
  );
};

export default PrivateRoute;
