import Swali from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swali)

// MySwal.fire({
//   title: <p>Hello World</p>,
//   didOpen: () => {
//     // `MySwal` is a subclass of `Swal` with all the same instance & static methods
//     MySwal.showLoading()
//   },
// }).then(() => {
//   return MySwal.fire(<p>Shorthand works too</p>)
// })


const Toast = MySwal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
  didOpen: (toast) => {
      MySwal.showLoading();
      toast.addEventListener("mouseenter", MySwal.stopTimer);
      toast.addEventListener("mouseleave", MySwal.resumeTimer);
  },
});

const Loading = MySwal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1500,
});

const BaseToast = (icon, title) => {
  return Toast.fire({ icon, title, timer: 1000 });
};

const ToastError = async (title) => {
  return await BaseToast("error", title);
};

const ToastInfo = async (title) => {
  return await BaseToast("info", title);
};

const ToastSuccess = async (title) => {
  return await BaseToast("success", title);
};

const ToastWarning = async (title) => {
  return await BaseToast("warning", title);
};

const ToastQuestion = async (title) => {
  return await BaseToast("question", title);
};

const showLoading = (title) => {
  return Loading.fire({
      html: `<img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif" width="50" alt="Cargando" /> ${title}`,
  });
};

const hideLoading = async (callback, message) => {
  await callback(message);
  return;
};
export {
  ToastError,
  ToastInfo,
  ToastSuccess,
  ToastWarning,
  ToastQuestion,
  showLoading,
  hideLoading,
};
